import $ from 'jquery';
import { gsap } from 'gsap';

import { $Elements, Animations, Classes, Events } from '../global/constants';

// ---------- Init Scroll Animation ----------

export const initScrollAnimations = () => {
	// ---------- Animated Section ----------

	$('.animated-section:not(.transition-clone)').each(function() {

		Animations.global.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					once: true,
					start: 'top 80%',
					toggleClass: Classes.active,
				}
			});

			gsap.timeline({
				scrollTrigger: {
					start: -1,
					end: 10,
					toggleClass: {
						targets: document.body,
						className: 'on-top'
					},
				}
			});

		});
	});

	// ---------- Header ----------

	$Elements.HeaderWrapper.each(function() {
		const headerWrapper = this;

		Animations.mobile.min.push(() => {
			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: headerWrapper,
					start: () => headerWrapper.offsetHeight,
					triggerHook: 0,
					onEnter: () => {
						headerWrapper.classList.remove('changing');
						headerWrapper.classList.add('active', 'small');
					},
					onLeaveBack: () => {
						headerWrapper.classList.add('changing');

						gsap.delayedCall(0.5, () => {
							headerWrapper.classList.remove('changing');
							headerWrapper.classList.remove('active', 'small');
						});
					},
				},
			});
		});
	});

	// ---------- Page Sidebar ---------

	$('section.page-level-2').each(function() {

		const wrapper = this;
		const sidebar = wrapper.querySelector('#child-pages');

		if(wrapper.offsetHeight > window.innerHeight) {

			Animations.mobile.min.push(() => {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: sidebar,
						start: 'center center',
						endTrigger: wrapper,
						end: 'bottom bottom',
						scrub: true,
						invalidateOnRefresh: true,
						pinSpacing: false,
						pin: sidebar,
						//markers: true,
					},
				});
			});
		}
	});

	// ---------- Content Sections ----------

	$('.parallax-section:not(.fixed):not(.transition-clone):not(.hero-section)').each(function() {

		Animations.mobile.max.push(() => this.classList.remove(Classes.moving));

		Animations.mobile.min.push(() => {

			this.classList.add(Classes.moving);

			const image = this.querySelector(':scope .section-image');
			const speed = parseInt(this.dataset.speed, 10);

			if(image) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				const offset = () => image.offsetHeight - ((image.offsetHeight - this.offsetHeight) / 2);

				timeline.fromTo(
					image,
					{ y: () => offset() * (speed / 100), ease: 'none' },
					{ y: () => offset() * (-speed / 100), ease: 'none' }
				);
			}
		});
	});

	$('.hero-section:not(.fixed):not(.transition-clone)').each(function() {

		Animations.mobile.max.push(() => this.classList.remove(Classes.moving));

		Animations.mobile.min.push(() => {

			this.classList.add(Classes.moving);

			const header_image = this.querySelector(':scope .section-image ');
			const speed = 50;

			if(header_image) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top 140px',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				const offset = () => header_image.offsetHeight - ((header_image.offsetHeight - this.offsetHeight) / 2);

				timeline.fromTo(
					header_image,
					{ y: 0, ease: 'none' },
					{ y: () => offset() * (speed / 100), ease: 'none' }
				);
			}
		});
	});
};

$Elements.document.on(Events.page.enter, () => initScrollAnimations());
