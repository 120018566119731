import $ from 'jquery';
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { deviceType } from 'detect-it';
import { Crawler } from 'es6-crawler-detect';
import * as bootstrap from 'bootstrap';

import { $Elements, Animations, Classes, Events } from './constants';
import { fixMobileHover, getCookiesAllowed, initGTM, initLightboxes, isDisclaimerAccepted, resetCookiesAllowed, resetGTMConsent, setCookiesAllowed, setDisclaimerAccepted, smoothScrollTo, sortAnimations, wait } from './helpers';


// ---------- LazyLoad Config ----------

lazySizes.cfg.throttleDelay = 66;
lazySizes.cfg.preloadAfterLoad = true;

// ---------- Fix Scroll ----------

history.scrollRestoration = "manual";

// ---------- Touch Screen ----------

if(deviceType === 'touchOnly') {
	$Elements.html.addClass('touchscreen');
}

// ---------- Fix VH ----------

$Elements.window.on('resize', () => document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`));
$Elements.window.trigger('resize');

// ---------- jQuery Related ----------

$Elements.document.on(Events.page.enter, async() => {

	// ---------- Fix Mobile Hover ----------

	fixMobileHover();

	// ---------- Handle Aniamtions ----------

	ScrollTrigger.matchMedia(sortAnimations(Animations));

	// ---------- Links ----------

	$Elements.PageWrapper.on('click', 'a[href="#"], .void-link', (e) => e.preventDefault());

	$Elements.PageWrapper.on('click', '[data-scroll-to], [target="scroll-to"]', function(e) {
		e.preventDefault();

		const self = $(this);
		const target = (self.is('a')) ? $(self.attr('href')) : $(self.data('scroll-to'));

		if(target.length) {
			smoothScrollTo(target);
		}
	});

	$Elements.PageWrapper.on('click', '.select-dropdown .dropdown-menu .btn', function() {
		$(this).closest('.dropdown').find('.dropdown-toggle').html($(this).text());
	});

	// ---------- Select Categories ----------

	const isArchivePage = window.location.pathname === '/blog/' || window.location.pathname === '/blog';

	const currentPath = window.location.pathname;
	const selectedCategory = currentPath.includes('/category/') ? currentPath.split('/category/')[1].split('/')[0] : '';

	if(selectedCategory) {
		$('#category-filter').val(selectedCategory);
	}
	else if(isArchivePage) {
		$('#category-filter').val('');
	}

	$('#category-filter').on('change', function() {
		const selectedSlug = $(this).val();
		const baseUrl = '/blog/';

		if(selectedSlug === '') {
			window.location.href = window.location.origin + baseUrl;
		}
		else {
			window.location.href = window.location.origin + baseUrl + 'category/' + selectedSlug + '/';
		}
	});

	// ---------- Select Talks Tags ----------

	const isArchivePageTalk = window.location.pathname === '/talks/' || window.location.pathname === '/talks';

	const currentPathTalk = window.location.pathname;
	const selectedCategoryTalk = currentPathTalk.includes('/talk-tag/') ? currentPathTalk.split('/talk-tag/')[1].split('/')[0] : '';

	if(selectedCategoryTalk) {
		$('#talk-filter').val(selectedCategoryTalk);
	}
	else if(isArchivePageTalk) {
		$('#talk-filter').val('');
	}

	$('#talk-filter').on('change', function() {
		const selectedSlugTalk = $(this).val();
		const baseUrlTalk = '/talks/';

		if(selectedSlugTalk === '') {
			window.location.href = window.location.origin + baseUrlTalk;
		}
		else {
			window.location.href = window.location.origin + '/talk-tag/' + selectedSlugTalk + '/';
		}
	});


	// ---------- GLightbox ----------

	initLightboxes();


	// ---------- Tooltip ----------

	const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
	const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

	// ---------- Cookies ----------

	initGTM();

	const initCookiesAllowed = async() => {

		const cookies = document.querySelector('#cookies-allowed');

		if(cookies) {

			cookies.classList.remove(Classes.hidden);
			await wait(100);
			cookies.classList.add(Classes.show);

			$(cookies).on('click', '[data-bs-dismiss="alert"]', function() {
				setCookiesAllowed(this.dataset.allowed);
			});

			$(cookies).on('closed.bs.alert', function() {
				cookies.classList.add(Classes.hidden);
				document.body.append(cookies);
			});
		}
	};

	if(!getCookiesAllowed()) {
		await initCookiesAllowed();
	}

	$('[data-reset-cookies-allowed]').click(async function() {
		resetCookiesAllowed();
		resetGTMConsent();
		await initCookiesAllowed();
	});
});

// ---------- Disclaimer ----------

if(!isDisclaimerAccepted()) {

	const disclaimer = document.querySelector('#disclaimer');
	const CrawlerDetector = new Crawler();

	if(!CrawlerDetector.isCrawler(navigator.userAgent) && disclaimer) {

		disclaimer.classList.remove(Classes.hidden);
		$Elements.body.addClass(Classes.disclaimer);

		$(disclaimer).on('close.bs.alert', function() {
			setDisclaimerAccepted();
			$Elements.body.removeClass(Classes.disclaimer);
		});
	}
}

