import $ from 'jquery';

import { $Elements, Classes, Events } from './constants';
import { disableBodyScroll, enableBodyScroll, smoothScrollTo } from './helpers';

const body = $('body');

$Elements.document.on(Events.page.enter, () => {

	// ---------- Menu ----------

	$Elements.PageWrapper.on('click', '.mobile-menu-toggle', function(e) {
		e.preventDefault();

		var menu = document.getElementById("mobile-menu-wrapper");

		if ($Elements.body.hasClass(Classes.menuOpen)) {
			$Elements.body.removeClass(Classes.menuOpen);
			enableBodyScroll(menu);
		} else {
			$Elements.body.addClass(Classes.menuOpen);
			disableBodyScroll(menu);
		}
	});

	$('.mobile-menu-overlay').click(function(e) {
		e.preventDefault();

		$('.mobile-menu-toggle').trigger('click');
	});

	$Elements.PageWrapper.on('click', '.navbar-menu a, .menu-wrapper a', function(e) {

		e.preventDefault();

		var self = $(this);
		var link_target = self.attr('href');
		var section_target = link_target.substring(link_target.lastIndexOf('#'), link_target.length);

		if($Elements.body.hasClass('home') && section_target.substring(0, 1) === '#') {

			$Elements.body.removeClass('mobile-menu-open');

			$('html, body').stop().animate({
				scrollTop: $(section_target).offset().top - 70
			}, 1000);
		}
		else {
			window.location.href = link_target;
		}
	});
});

$Elements.document.on(Events.page.loaded, () => {

	const linkTarget = window.location.href;
	const sectionTarget = linkTarget.substring(linkTarget.lastIndexOf('#'), linkTarget.length);

	if(sectionTarget.substring(0, 1) === '#') {

		const section = $(sectionTarget);

		if(section.length) {
			smoothScrollTo(section, 0);
		}
	}
});
